<template>
  <v-card
    class="pr-10 pl-10 pt-4 pb-6 mb-10"
    min-height="500"
    flat
    id="itemPermalinkPage"
  >
    <v-card-title>
      <h4 class="white--text ml-n6" style="font-weight: 100">
        {{ customerName }}
      </h4>
    </v-card-title>
    <v-row class="py-0" v-if="item.value">
      <v-col cols="4" style="color: #475872; font-weight: 500">Value</v-col>
      <v-col>{{ item.value.value }}</v-col></v-row
    >

    <v-row v-for="detail in details" :key="detail[0]" class="py-0">
      <v-col cols="4" class="py-0" style="color: #475872; font-weight: 500">{{
        detail[0]
      }}</v-col>
      <v-col class="py-1">{{ detail[1] }}</v-col>
    </v-row>
    <v-row class="py-0">
      <v-col
        cols="4"
        style="color: #475872; font-weight: 500; margin-top: 0.5em"
      >
        Status
      </v-col>
      <v-row>
        <v-col cols="4" style="margin-left: 1em">
          <v-switch v-model="selectedItem" :label="selectedItemText"></v-switch>
        </v-col>
        <v-col cols="4">
          <v-card-actions>
            <v-btn
              color="accent"
              depressed
              width="120"
              height="34"
              style="margin-top: 10px"
              @click="updateItem"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-row>
    <v-row v-if="parentDetails.length > 0">
      <v-col>
        <h4 style="color: #475872; font-weight: 500" class="mb-4">Lineage</h4>
        <v-timeline>
          <v-timeline-item
            v-for="(parentDetail, index) in parentDetails"
            :key="index"
            small
          >
            <v-card
              class="elevation-0"
              :ripple="false"
              outlined
              @click="parentPermalink(parentDetail)"
            >
              <v-card-text>
                <span v-if="parentDetail.value"
                  ><b>Value:</b> {{ parentDetail.value.value }}<br
                /></span>
                <span v-if="parentDetail.item_type">
                  <b>Type:</b> {{ parentDetail.item_type }}<br
                /></span>
                <span v-if="parentDetail.id">
                  <b>ID:</b> {{ parentDetail.id }}<br
                /></span>
                <span v-if="parentDetail.created_at">
                  <b>Created:</b> {{ formatDate(parentDetail.created_at) }} <br
                /></span>
                <span v-if="parentDetail.modified_at">
                  <b>Modified:</b>
                  {{ formatDate(parentDetail.modified_at) }}</span
                >
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackBar" color="#58abe7" centered elevation="6">
      {{ snackBarContent }}
    </v-snackbar>
  </v-card>
</template>

<script>
import {
  getMonitoringItem,
  updateMonitoringItem,
  listItemTypes,
} from "@/clients/tracelight";
import { formatDate } from "@/utils/common";
import store from "../store/index";
export default {
  name: "customer-items-permalink",
  data: () => ({
    customerName: null,
    details: [],
    parentDetails: [],
    selectedItem: null,
    snackBar: false,
    snackBarContent: "",
    item: [],
    formatDate: formatDate,
  }),
  computed: {
    selectedItemText() {
      if (this.selectedItem) {
        return "Enabled";
      } else {
        return "Disabled";
      }
    },
    itemTypes() {
      return store.state.customerList.itemTypes.sort(this.sortArray);
    },
  },
  methods: {
    parentPermalink(parentDetail) {
      let routeData = this.$router.resolve({
        path:
          "/customer/" +
          this.$route.params.clientId +
          "/items/" +
          parentDetail.id,
      });
      window.open(routeData.href, "_blank");
    },
    updateItem() {
      const params = {
        client_id: this.$route.params.clientId,
        item_id: this.$route.params.itemId,
      };

      const payload = {
        enabled: this.selectedItem === true,
      };
      updateMonitoringItem(payload, params).then((result) => {
        this.snackBar = true;
        console.log(result);
        if (result.data.message) {
          this.snackBarContent = result.data.message;
        }
        this.getItemData();
      });
    },
    getParentData(parentId) {
      const params = {
        client_id: this.$route.params.clientId,
        item_id: parentId,
      };
      getMonitoringItem({}, params, {}).then((response) => {
        this.parentDetails.push(response.data.data);
        if (response.data.data.created_from) {
          this.getParentData(response.data.data.created_from.id);
        }
      });
    },
    getItemData() {
      store
        .dispatch("customerList/getCustomerById", {
          client_id: this.$route.params.clientId,
        })
        .then((r) => (this.customerName = r.name));
      const params = {
        client_id: this.$route.params.clientId,
        item_id: this.$route.params.itemId,
      };
      getMonitoringItem({}, params, {}).then((response) => {
        this.item = response.data.data;
        console.log(this.item);
        this.details = [];
        if (
          this.itemTypes.filter((type) => type.id == this.item.item_type)
            .length > 0
        ) {
          this.details.push([
            "Item Type",
            this.itemTypes.filter((type) => type.id == this.item.item_type)[0]
              .description,
          ]);
        }
        if (this.item.id) {
          this.details.push(["Item ID", this.item["id"]]);
        }
        if (this.item.created_at) {
          this.details.push(["Created", formatDate(this.item["created_at"])]);
        }
        if (this.item.modified_at) {
          this.details.push(["Modified", formatDate(this.item["modified_at"])]);
        }
        if (this.item.created_by) {
          this.details.push(["Created By", this.item.created_by.email]);
        }
        if (this.item.created_from) {
          this.details.push([
            "Parent Value",
            this.item.created_from.value.value,
          ]);
          this.details.push(["Parent ID", this.item.created_from.id]);
          this.getParentData(this.item.created_from.id);
        }
        this.selectedItem = false;
        if (this.item.enabled) {
          this.selectedItem = true;
        }
      });
    },
  },
  beforeCreate() {
    listItemTypes({}, { results_per_page: 3000 }).then(function (result) {
      store.commit("customerList/fillItemTypes", result.data.data);
    });
  },
  mounted() {
    this.getItemData();
  },
};
</script>
<style>
.v-application--is-ltr
  #itemPermalinkPage
  .v-timeline-item__body
  > .v-card:before,
.v-application--is-ltr #itemPermalinkPage .v-timeline-item__body .v-card:after {
  display: none;
}
</style>
